.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.uppercase {
  font-family: 'FiraGo';
  -moz-font-feature-settings: 'case';
  -webkit-font-feature-settings: 'case';
  font-feature-settings: 'case' on;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MuiAccordionSummary-root {
  padding: 0px;
  min-height: 40px;
}

.MuiAccordionSummary-root.Mui-expanded {
  min-height: 40px !important;
  max-height: 40px !important;
}

.MuiAccordionDetails-root {
  padding: 5px 15px 5px !important;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.simplebar-scrollbar::before {
  background-color: #757575;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

ul.images {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 15px;
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.images>li {
  position: relative;
  padding-top: 66%;
}

ul.images>li img {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

@media (max-width: 567px) {
  ul.images {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #777;
}

.MuiNativeSelect-root:after {
  border-bottom: 0px solid !important;
}